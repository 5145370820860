<template>
  <div>
    <div class="flix-navbar flix-navbar-small">
      <div class="flix-container-fluid" style="margin-top: 30px; margin-bottom: 30px;">
        <div class="flix-row">
          <div class="flix-col-md-12" style="margin: 10px 0 30px 0">
            <div><flixIcon id="info-sign" /> <b>{{ $t('email.testingInfo.title') }}</b></div>
            {{ $t('email.testingInfo.text') }}
          </div>
          <div class="flix-col-md-12 flix-text-center">
            <h5 class="flix-html-h5">{{ $t('message.tipHeader') }}</h5>
          </div>
          <div class="flix-col-md-4 flix-col-xs-12 flix-text-center" v-for="(tip, index) in getTips()" :key="tip">
            <h3 class="flix-html-h3">{{ index * 1 + 1 }}.</h3>
            <div class="flix-bg-warning flix-text-warning flix-html-strong flix-form-group">{{ $t('tips[' + tip + ']') }}</div>
            <div v-if="checked[index]" class="flix-text-success flix-bg-success">
              <flixIcon :id="'ok'" /> {{ $t('message.done') }}
            </div>
            <div v-else class="editBtns">
              <router-link class="flix-btn flix-btn-default" @click.native="scrollToTop()" :to="edit[index][0]">{{ edit[index][1] }}</router-link>
              <router-link class="flix-btn flix-btn-default" @click.native="scrollToTop()" :to="{name: 'dashboardHelp'}">{{ $t('message.needHelp') }}</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data () {
    return {
      checked: {},
      edit: {
        0: [{ name: 'dashboardProfile', params: { id: 'profile' } }, this.$t('message.resend', { name: this.$t('reminder.email') })],
        1: [{ name: 'dashboardProfile', params: { id: 'profile' } }, this.$t('message.edit', { name: this.$t('message.profile') })],
        2: [{ name: 'dashboardAssistent', params: { id: 'all' } }, this.$t('message.edit', { name: this.$tc('message.calendar', 1) })],
        3: [{ name: 'dashboardPublish', params: { id: 'all' } }, this.$t('message.embedCalendar')],
        4: [{ name: 'dashboardPageBuilder', params: { id: 'all', open: 0 } }, this.$t('message.edit', { name: this.$tc('message.page', 2) })],
        5: [{ name: 'dashboardReminder', params: { id: 'all' } }, this.$t('message.edit', { name: this.$tc('message.reminder', 2) })],
        6: [{ name: 'dashboardHolidays' }, this.$t('message.edit', { name: this.$tc('message.holidays', 2) })]
      }
    }
  },
  methods: {
    scrollToTop () {
      window.scrollTo(0, 0)
    },
    getTips () {
      var tips = Object.keys(this.$t('tips'))

      if (this.$store.getters.user.status === 'validated') {
        this.checked[0] = true
      }
      if (this.$store.getters.user.first_name !== '') {
        this.checked[1] = true
      }
      if (Object.keys(this.$store.getters.assistents).length) {
        this.checked[2] = true
      }
      return tips
    }
  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
  .flix-col-md-4
    height: 140px
    .editBtns
      a
        text-decoration: underline
      a:first-of-type
        margin-right: 12px
  .flix-navbar
    background-color: #eee
    border: 0
    font-size: 12pt
    margin-top: 50px
    transition: all 0.5s ease-in-out
    &:hover
      box-shadow: none
    .flix-html-h3
      text-transform: uppercase
    li.flix-html-li
      line-height: 1.2em
      margin-bottom: 23px

    .flix-html-a
      color: inherit

    .router-link-exact-active
      font-weight: bold

  @media (max-width: 500px)
    .flix-col-md-4
      height: inherit
    .editBtns
      a
        display: block
      a:first-of-type
        margin-right: 0
        margin-bottom: 10px
</style>
